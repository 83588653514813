<template>
  <button
    class="product-variant-button"
    :class="{
      'product-variant-button--active': active,
      'product-variant-button--disabled': disabled,
    }"
    :title="inactive ? 'Indisponível' : description"
    :disabled="inactive"
  >
    <div v-if="image" class="product-variant-button-img-wrapper">
      <!-- <img
        :src="image"
        :alt="description"
        loading="lazy"
        onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
      /> -->

      <ImageItem
        custom-class="img img-responsive"
        :source="image"
        :description="description"
      />
    </div>
    <span v-else>{{ description }}</span>
  </button>
</template>

<script>
import ImageItem from "@/components/image/ImageItem";

export default {
  name: "ProductVariantButton",
  components: {
    ImageItem,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.product-variant-button {
  overflow-wrap: anywhere;
  max-width: 200px;
  height: auto;
  min-width: 46px;
  padding: 5px;
  overflow: hidden;
  border-radius: 6px;
  border: 2px solid gainsboro;
  font-size: 14px;
  display: block;
  background: white;

  &--active {
    border-color: var(--brand-primary, $brand-primary) !important;
    color: var(--brand-primary, $brand-primary) !important;
    // border-width: 3px;
    font-weight: 600;
    opacity: 1;
  }

  &--disabled {
    border-color: #cdcdcd;
    opacity: 0.7;
    background: #f3f3f3;

    border-style: dotted;
    background: linear-gradient(
      to right bottom,
      transparent calc(50% - 1px),
      #cdcdcd,
      transparent calc(50% + 1px)
    );
    color: unset;
    // filter: grayscale(50%);
  }

  &:hover {
    border-color: #8d8d8d;
  }

  .product-variant-button-img-wrapper {
    // width: 50px;
    // height: 50px;
    height: 36px;
    display: flex;
  }

  img {
    max-width: 36px;
    max-height: 36px;
    margin: auto;
    padding: 0px;

    @media (max-width: 767px) {
      max-width: 26px;
      max-height: 26px;
    }
  }
}
</style>
