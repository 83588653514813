import Vue from "vue";
import VueRouter from "vue-router";
import VueCookie from "vue-cookie";

// import moment from "moment";
import Vuelidate from "vuelidate";

import { init, vueRouterInstrumentation } from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import VeeValidate from "vee-validate";

import "./app/directives";
import "bootstrap-sass/assets/javascripts/bootstrap.js";
import "slick-carousel/slick/slick.min.js";

import "@mdi/font/css/materialdesignicons.css";

import VueMeta from "vue-meta";

// VueDragscroll - Scroll ao clicar e arrastar
import VueDragscroll from "vue-dragscroll";

// Notifications - Utilização: Usado para exibir notificações (popup flutuante) no sistema.
import Notifications from "vue-notification";

// Selects
import Multiselect from "vue-multiselect";

// v-mask
import VueMask from "di-vue-mask";
import ToggleButton from "vue-js-toggle-button";
import vSelect from "vue-select";
import VueSelect from "vue-cool-select";

import LazyLoadDirective from "./directives/LazyLoadDirective";
import App from "./App.vue";
import VueGoodTable from "./components/root/table/index.js";

import * as dpcAxios from "@/dpcAxios";
import * as Account from "./Account";
import store from "./vuex";
import router from "./router/index";
import "vue-select/dist/vue-select.css";

Vue.directive("lazyload", LazyLoadDirective);

require("@/assets/css/sass/app.scss");

require("bootstrap-select");

Vue.use(ToggleButton);

Vue.component("VSelect", vSelect);

// window.moment = moment;
window.Account = Account;
window.dpcAxios = dpcAxios;

window.jivo_api = null;

Vue.use(VueSelect, {
  theme: "bootstrap", // or 'material-design'
});
Vue.use(Vuelidate);
Vue.use(VueMeta);
Vue.use(VeeValidate);
Vue.use(VueCookie);
// Vue.use(VueStripePayment);
Vue.config.productionTip = false;
Vue.use(VueGoodTable);
Vue.use(VueDragscroll);
Vue.use(Notifications);
Vue.component("Multiselect", Multiselect);

// import wb from "./registerServiceWorker";

// Vue.prototype.$workbox = wb;

Vue.use(VueMask);

const sentryDns = process.env.VUE_APP_SENTRY_DNS;
// if (sentryDns) {
//   init({
//     Vue,
//     dsn: process.env.VUE_APP_SENTRY_DNS,
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: vueRouterInstrumentation(router),
//         tracePropagationTargets: ["localhost", process.env.VUE_APP_URL, /^\//],
//       }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

new Vue({
  router,
  store,
  el: "#app",
  render: (h) => h(App),
}).$mount("#app");

// ga("set", "page", router.currentRoute.path);
// ga("send", "pageview");

router.afterEach((to, from) => {
  gtag("set", "page", to.fullPath);
  gtag("send", "pageview");
});
