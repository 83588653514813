<template>
  <div class="payment-resume-info">
    <div v-if="icon" class="pri-icon">
      <!-- <img src="@/assets/img/pagamento/cardIcon.svg" alt /> -->
      <!-- <img :src="icon" alt /> -->
      <i :class="icon"></i>
    </div>
    <div class="pri-content">
      <div class="pc-title">
        <span>{{ title }}</span>
      </div>
      <p>{{ content }}</p>
    </div>
    <div class="pri-action"><slot name="action"></slot></div>
  </div>
</template>

<script>
export default {
  name: "PaymentResumeInfo",
  props: {
    icon: {
      required: false,
    },
    title: {
      required: true,
    },
    content: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-resume-info {
  padding: 10px 0px;
  display: flex;
  align-items: center;

  .client_address_info {
    padding-bottom: 4px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .pri-content {
    flex: 1;

    .pc-title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.03em;
      color: #737373;
    }

    p {
      // margin: 0px 69px 5.5px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      margin-bottom: 3px;
      color: #4a4a4a;
    }
  }
  .pri-icon {
    padding-right: 5px;
    align-self: center;

    i {
      width: 24px;
      margin: 0 20px;
      color: var(--brand-primary, $brand-primary);
    }
  }
}
</style>
