<template>
  <div class="left-top">
    <div class="div-brand">
      <div class="navbar-header">
        <button
          id="btn-sidenav"
          type="button"
          class="navbar-toggle collapsed"
          @click="menuIsActive()"
        >
          <span class="sr-only">Menu</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <Sidebar
        :active="isMenuActive"
        :lado="'left'"
        :scroll-active="false"
        titulo="Categorias"
        @closeside="menuIsActive"
      >
        <HeaderMenuResponsivo @closeside="menuIsActive"></HeaderMenuResponsivo>
      </Sidebar>
      <router-link to="/" class="navbar-brand nav-logo-img">
        <img
          v-if="logoUrl"
          :src="logoUrl"
          class="img-logo-header img-responsive"
          :alt="appShortName"
        />
        <img
          v-else
          src="~@/assets/img/logoDPC.svg"
          class="img-logo-header img-responsive"
          :alt="appShortName"
        />

        <img
          v-if="logoUrl"
          :src="logoUrl"
          class="img-logo-header-mobile img-responsive"
          :alt="appShortName"
        />
        <img
          v-else
          src="~@/assets/img/logoDPC-mobile.svg"
          class="img-logo-header-mobile img-responsive"
          :alt="appShortName"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/root/sidebar/Sidebar";
import HeaderMenuResponsivo from "@/components/root/header/HeaderMenuResponsivo";
import bus from "@/utils/events/bus";

export default {
  name: "LeftTop",
  components: { Sidebar, HeaderMenuResponsivo },
  data() {
    return {
      isMenuActive: false,
      // logoUrl:
      //   process.env.VUE_APP_LOGO_URL &&
      //   process.env.VUE_APP_LOGO_URL.includes("@")
      //     ? require(process.env.VUE_APP_LOGO_URL)
      //     : process.env.VUE_APP_LOGO_URL,
      logoUrl: process.env.VUE_APP_LOGO_URL,
      appShortName: process.env.VUE_APP_NAME_SHORT,
    };
  },
  mounted() {
    bus.$on("toggleHeaderMenuResponsible", () => {
      this.menuIsActive();
    });
  },
  beforeDestroy() {
    bus.$off("toggleHeaderMenuResponsible");
  },
  methods: {
    menuIsActive() {
      this.isMenuActive = !this.isMenuActive;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.left-top {
  display: flex;
  // width: 25%;
  margin-right: 20px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.img-logo-header-mobile {
  display: none;
  max-height: 25px;
}

// display img-logo-header-mobile on mobile and tablet
@media (max-width: $screen-sm) {
  .img-logo-header-mobile {
    display: flex;
  }

  .img-logo-header {
    display: none !important;
  }
}

.img-logo-header {
  display: flex;
  // max-height: 45px;
  max-height: 60px;
  max-width: 215px;
}

.div-brand {
  display: flex;
  align-items: center;
  padding: 0px;
}

.navbar-brand {
  padding: 0px;
  height: auto;
}

// .nav-logo-img {
//   width: 200px;
// }

@media (max-width: 991px) {
  /*   .img-logo-header {
    display: none;
  } */
  // .left-top {
  //   width: 50%;
  // }
  /*   .nav-logo-img {
    width: 100px;
  } */
  .div-brand {
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar-header {
    margin-top: 0px;
    display: flex;
    align-items: center;
  }

  .navbar-toggle .icon-bar {
    display: block;
    width: 35px;
    height: 3px;
    border-radius: 1px;
  }

  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 5px;
  }

  .navbar-inverse .navbar-toggle {
    border-color: #fff;
    margin-left: 0px;
  }

  .navbar-toggle {
    padding: 14px 8px;
  }

  .navbar-inverse .navbar-toggle.collapsed:hover,
  .navbar-inverse .navbar-toggle.collapsed:focus {
    background-color: transparent;
    border-color: #fff;
  }

  .navbar-inverse .navbar-toggle:hover,
  .navbar-inverse .navbar-toggle:focus {
    background-color: #90ce3e;
    border-color: #fff;
  }

  .navbar-inverse .navbar-nav > .active > a,
  .navbar-inverse .navbar-nav > .active > a:hover,
  .navbar-inverse .navbar-nav > .active > a:focus {
    background-color: transparent;
    border-bottom: 0px solid;
    margin-bottom: 0px;
  }

  #middle-top-menu {
    margin-top: 5px;
    margin-bottom: 6px;
  }

  .navbar-toggle {
    left: 260px;
    border: none;
    background-color: #90ce3e;
    z-index: 9999 !important;
    position: fixed;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin-right: 15px;
    padding: 21px 0px;

    &.collapsed {
      border: none;
      background-color: transparent;
      background: transparent;
      position: relative;
      left: 0;
    }
  }
}

@media (max-width: 525px) {
  .navbar-toggle.collapsed .icon-bar {
    width: 25px;
  }

  .navbar-toggle.collapsed {
    padding: 7px 15px;
    margin: 0;
  }

  .navbar-brand {
    // padding: 10px 0px 2px 0px;
    padding: 8px 0px 5px 0px;
    height: 100%;
  }

  .left-top {
    // margin-right: 0px;
    flex: auto;
  }
}
</style>
