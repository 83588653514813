// import Produto from "./components/Main.vue";

export default [
  {
    path: "/produto/:codigo/:desc?",
    name: "produtoRota",
    component: () =>
      import(
        /* webpackChunkName: "view-produto" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
    // props: (route) => ({ query: route.query.codigo }, { query: route.query.categoria }) },

    props: (route) => (
      { codigo: route.params.codigo }, { categoria: route.query.categoria }
    ),
  },
];
