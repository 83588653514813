// import Catalogo from "./components/Main.vue";

export default [
  {
    path: "/catalogo/:categoriaName?",
    name: "catalogoRota",
    component: () =>
      import(
        /* webpackChunkName: "view-catalogo" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
    props: (route) => (
      { query: route.query.busca },
      { params: route.query.categoria },
      { query: route.query.fornecedor },
      { query: route.query.sub },
      { query: route.query.ordem },
      { query: route.query.visualizacao },
      { query: route.query.ordenacao }
    ),
  },
];
