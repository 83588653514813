<template>
  <div id="app" class="wrapper">
    <div class="card-form">
      <div class="card-form__inner">
        <img
          style="padding: 5px 30px"
          src="@/assets/img/checkout/card-brands.png"
          alt="Bandeiras de cartão aceitas. Visa, MasterCard, American Express, Hipercard e Elo"
          width="100%"
        />
        <div class="card-input">
          <label for="cardNumber" class="card-input__label"
            >Número do cartão</label
          >
          <input
            id="cardNumber"
            v-model="paymentData.cardNumber"
            v-mask="generateCardNumberMask"
            type="text"
            class="card-input__input"
            data-ref="cardNumber"
            autocomplete="off"
            maxlength="20"
            required
            @focus="focusInput"
            @blur="blurInput"
          />
          <p v-if="cardError" class="error">{{ cardError }}</p>
        </div>
        <div class="card-input">
          <label for="cardName" class="card-input__label">Nome no cartão</label>
          <input
            id="cardName"
            v-model="paymentData.cardName"
            type="text"
            class="card-input__input"
            data-ref="cardName"
            autocomplete="off"
            maxlength="26"
            required
            @focus="focusInput"
            @blur="blurInput"
          />
          <p v-if="nameError" class="error">{{ nameError }}</p>
        </div>

        <div class="card-form__row">
          <div class="card-form__col -cvv">
            <div class="card-input">
              <label for="cardCvv" class="card-input__label">CVV</label>
              <input
                id="cardCvv"
                v-model="paymentData.cardCvv"
                v-mask="'####'"
                type="text"
                class="card-input__input"
                maxlength="4"
                autocomplete="off"
                required
                @focus="flipCard(true)"
                @blur="flipCard(false)"
              />
              <p v-if="cvvError" class="error">{{ cvvError }}</p>
            </div>
          </div>
          <div class="card-form__group card-input">
            <label for="cardMonth" class="card-input__label"
              >Data de vencimento</label
            >
            <select
              id="cardMonth"
              v-model="paymentData.cardMonth"
              class="card-input__input -select"
              data-ref="cardDate"
              required
              @focus="focusInput"
              @blur="blurInput"
            >
              <option value disabled selected>Mês</option>
              <option
                v-for="n in 12"
                :key="n"
                :value="n < 10 ? '0' + n : n"
                :disabled="n < minCardMonth"
              >
                {{ n < 10 ? "0" + n : n }}
              </option>
            </select>
            <select
              id="cardYear"
              v-model="paymentData.cardYear"
              class="card-input__input -select"
              data-ref="cardDate"
              required
              @focus="focusInput"
              @blur="blurInput"
            >
              <option value disabled selected>Ano</option>
              <option
                v-for="(n, $index) in 12"
                :key="n"
                :value="$index + minCardYear"
              >
                {{ $index + minCardYear }}
              </option>
            </select>
            <p v-if="dateError" class="error">{{ dateError }}</p>
          </div>
        </div>

        <div class="card-input">
          <label for="cardName" class="card-input__label"
            >CPF/CNPJ títular do cartão</label
          >
          <input
            id="cardOwnerDocument"
            v-model="paymentData.cardOwnerDocument"
            type="text"
            class="card-input__input"
            maxlength="18"
            autocomplete="off"
            required
          />

          <!-- <p class="error" v-if="nameError">{{ nameError }}</p> -->
        </div>
        <div class="card-input saveCardSelect">
          <input
            id="saveCard"
            v-model="paymentData.saveCard"
            type="checkbox"
            name="saveCard"
            checked
          />
          <label for="saveCard" class="card-input__label">
            Salvar para próximas compras.
          </label>
        </div>

        <div class="card-input">
          <label for="cardName" class="card-input__label"
            >Número de Parcelas</label
          >
          <select
            id="cardSelectqt"
            v-model="paymentData.cardParcels"
            class="card-input__input"
            data-ref="cardSelectqt"
            autocomplete="off"
            disabled
          >
            <option :value="paymentData.cardParcels" selected>
              {{ paymentData.cardParcels }}x de R$
              {{ mascaraValor(paymentAmount / paymentData.cardParcels) }}
            </option>
          </select>
          <p v-if="nameError" class="error">{{ nameError }}</p>
        </div>
        <div class="saveCardSelectMobile">
          <input
            id="saveCard2"
            v-model="paymentData.saveCard"
            type="checkbox"
            name="saveCard"
            checked
          />
          <label for="saveCard2" class="card-input__label"
            >Salvar cartão para sua próxima compra</label
          >
        </div>
      </div>
      <div class="card-list">
        <div class="card-item" :class="{ '-active': isCardFlipped }">
          <div class="card-item__side -front">
            <div
              ref="focusElement"
              class="card-item__focus"
              :class="{ '-active': focusElementStyle }"
              :style="focusElementStyle"
            ></div>
            <div class="card-item__cover">
              <!-- <img
                v-bind:src="'/public/images/' + currentCardBackground + '.jpeg'"
                class="card-item__bg"
              />-->
            </div>

            <div class="card-item__wrapper">
              <div class="card-item__top">
                <img src="/public/images/chip.png" class="card-item__chip" />
                <div class="card-item__type">
                  <transition name="slide-fade-up">
                    <img
                      v-if="getCardType"
                      :key="getCardType"
                      :src="'/public/images/' + getCardType + '.png'"
                      alt
                      class="card-item__typeImg"
                    />
                  </transition>
                </div>
              </div>
              <label
                ref="cardNumber"
                for="cardNumber"
                class="card-item__number"
              >
                <template v-if="getCardType === 'amex'">
                  <span v-for="(n, $index) in amexCardMask" :key="$index">
                    <transition name="slide-fade-up">
                      <div
                        v-if="
                          $index > 4 &&
                          $index < 14 &&
                          cardNumber.length > $index &&
                          n.trim() !== ''
                        "
                        class="card-item__numberItem"
                      >
                        *
                      </div>
                      <div
                        v-else-if="cardNumber.length > $index"
                        :key="$index"
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                      >
                        {{ cardNumber[$index] }}
                      </div>
                      <div
                        v-else
                        :key="$index + 1"
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                      >
                        {{ n }}
                      </div>
                    </transition>
                  </span>
                </template>

                <template v-else>
                  <span v-for="(n, $index) in otherCardMask" :key="$index">
                    <transition name="slide-fade-up">
                      <div
                        v-if="
                          $index > 4 &&
                          $index < 15 &&
                          cardNumber.length > $index &&
                          n.trim() !== ''
                        "
                        class="card-item__numberItem"
                      >
                        *
                      </div>
                      <div
                        v-else-if="cardNumber.length > $index"
                        :key="$index"
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                      >
                        {{ cardNumber[$index] }}
                      </div>
                      <div
                        v-else
                        :key="$index + 1"
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                      >
                        {{ n }}
                      </div>
                    </transition>
                  </span>
                </template>
              </label>
              <div class="card-item__content">
                <label ref="cardName" for="cardName" class="card-item__info">
                  <!-- <div class="card-item__holder">Nome e sobrenome</div> -->
                  <transition name="slide-fade-up">
                    <div v-if="cardName.length" key="1" class="card-item__name">
                      <transition-group name="slide-fade-right">
                        <span
                          v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')"
                          v-if="$index === $index"
                          :key="$index + 1"
                          class="card-item__nameItem"
                          >{{ n }}</span
                        >
                      </transition-group>
                    </div>
                    <div v-else key="2" class="card-item__name">
                      Nome e sobrenome
                    </div>
                  </transition>
                </label>
                <div ref="cardDate" class="card-item__date">
                  <!-- <label for="cardMonth" class="card-item__dateTitle"
                    >Expiração</label
                  > -->
                  <label for="cardMonth" class="card-item__dateItem">
                    <transition name="slide-fade-up">
                      <span v-if="cardMonth" :key="cardMonth">{{
                        cardMonth
                      }}</span>
                      <span v-else key="2">MM</span>
                    </transition>
                  </label>
                  /
                  <label for="cardYear" class="card-item__dateItem">
                    <transition name="slide-fade-up">
                      <span v-if="cardYear" :key="cardYear">{{
                        String(cardYear).slice(2, 4)
                      }}</span>
                      <span v-else key="2">AA</span>
                    </transition>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-item__side -back">
            <div class="card-item__cover">
              <!-- <img
                v-bind:src="'/public/images/' + currentCardBackground + '.jpeg'"
                class="card-item__bg"
              />-->
            </div>
            <div class="card-item__band"></div>
            <div class="card-item__cvv">
              <div class="card-item__cvvTitle">CVV</div>
              <div class="card-item__cvvBand">
                <span v-for="(n, $index) in paymentData.cardCvv" :key="$index"
                  >*</span
                >
              </div>
              <div class="card-item__type">
                <img
                  v-if="getCardType"
                  :src="'/public/images/' + getCardType + '.png'"
                  class="card-item__typeImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/events/bus";

import { mascaraValor } from "@/app/global";

export default {
  name: "CreditCard",
  props: {
    paymentAmount: {
      required: false,
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      currentCardBackground: Math.floor(Math.random() * 25),
      // cardName: "",
      // cardNumber: "",
      // cardMonth: "",
      // cardYear: "",
      cardCvv: "",
      cardParcels: "",
      // minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      loadingFinalizar: false,
      cardError: "",
      nameError: "",
      dateError: "",
      cvvError: "",
      dadosPedido: Object,
      saveCard: true,
      payment: {},
      formatedCardNumber: null,
      bandeira: "",
    };
  },
  computed: {
    paymentData: {
      set(data) {
        // console.log(data);
        this.$store.dispatch("paymentData", data);
      },
      get() {
        return this.$store.getters.paymentData;
      },
    },
    cardNumber() {
      return this.paymentData.cardNumber || "";
    },
    cardName() {
      return this.paymentData.cardName || "";
    },
    cardMonth() {
      return this.paymentData.cardMonth || "";
    },
    cardYear() {
      return this.paymentData.cardYear || "";
    },
    unformattedCardNumber() {
      return this.cardNumber.replace(/\s+/g, "");
    },
    getCardType() {
      let number = this.unformattedCardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.paymentData.cardYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
    minCardYear() {
      if (this.paymentData.cardMonth < new Date().getMonth()) {
        return new Date().getFullYear() + 1;
      }
      return new Date().getFullYear();
    },
  },
  watch: {
    unformattedCardNumber() {
      this.formatedCardNumber = this.unformattedCardNumber;
    },
    // cardYear() {
    //   if (this.cardMonth < this.minCardMonth) {
    //     this.cardMonth = "";
    //   }
    // },
    // cardNumber: function () {
    //   if (this.formatedCardNumber.length >= 15) {
    //     this.getBrand();
    //     this.payment = this.$store.getters.paymentData;
    //     this.payment.cardNumber = parseInt(this.formatedCardNumber);
    //     this.payment.cardBrand = this.bandeira;
    //     this.$store.dispatch("paymentData", this.payment);
    //   }
    // },
    // cardName: function (cardName) {
    //   if (cardName.length >= 4) {
    //     this.payment = this.$store.getters.paymentData;
    //     this.payment.cardName = cardName.toUpperCase();
    //     this.$store.dispatch("paymentData", this.payment);
    //   }
    // },
    // cardCvv: function (cardCvv) {
    //   if (cardCvv.length >= 3) {
    //     this.payment = this.$store.getters.paymentData;
    //     this.payment.cardCvv = parseInt(cardCvv);
    //     this.$store.dispatch("paymentData", this.payment);
    //   }
    // },
    // cardParcels: function (cardParcels) {
    //   if (cardParcels) {
    //     this.payment = this.$store.getters.paymentData;
    //     this.payment.cardParcels = cardParcels;
    //     this.$store.dispatch("paymentData", this.payment);
    //   }
    // },
    // cardMonth: function (cardMonth) {
    //   this.payment = this.$store.getters.paymentData;
    //   this.payment.cardMonth = cardMonth;
    //   this.$store.dispatch("paymentData", this.payment);
    // },
    // cardYear: function (cardYear) {
    //   this.payment = this.$store.getters.paymentData;
    //   this.payment.cardYear = cardYear;
    //   this.$store.dispatch("paymentData", this.payment);
    // },
    // saveCard: function (saveCard) {
    //   this.payment = this.$store.getters.paymentData;
    //   this.payment.saveCard = saveCard;
    //   this.$store.dispatch("paymentData", this.payment);
    // },
  },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    document.getElementById("cardNumber").focus();
    // if (this.paymentData.cardParcels) {
    //   this.cardParcels = this.$store.getters.paymentData;
    //   this.payment.cardParcels = cardParcels;
    //   this.$store.dispatch("paymentData", this.payment);
    // }
  },
  methods: {
    mascaraValor,
    /**
     * Função responsável por validar o número do cartão
     * Chamada ao click do botão no resumo do pedido
     */
    validateCreditCardNumber(inputNum) {
      var digit;
      var digits;
      var flag;
      var sum;
      var _i;
      var _len;
      flag = true;
      sum = 0;
      digits = `${inputNum}`.split("").reverse();
      for (_i = 0, _len = digits.length; _i < _len; _i++) {
        digit = digits[_i];
        digit = parseInt(digit, 15);
        if ((flag = !flag)) {
          digit *= 2;
        }
        if (digit > 9) {
          digit -= 9;
        }
        sum += digit;
      }

      return sum % 10 === 0;
    },

    /** Função responsável por verificar bandeira  */
    getBrand() {
      var cartoes = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})/,
        Mastercard: /^5[1-5][0-9]{14}/,
        Amex: /^3[47][0-9]{13}/,
        DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}/,
      };

      function testarCC(nr, cartoes) {
        for (var cartao in cartoes)
          if (nr.match(cartoes[cartao])) return cartao;
        return "";
      }
      this.paymentData.cardBrand = testarCC(
        this.unformattedCardNumber,
        cartoes
      );
    },

    /**
     * Função responsável por limpar o carrinho quando o pedido cancelado é o mesmo pedido que está aberto na sessão do usuário
     * Chamado quando o usuario cancela um pedido
     */

    limpaCarrinho() {
      // Zerando quantidade para remover os itens do carrinho
      const carrinhoAtual = "shouldCleanCart";
      this.$store.dispatch("addItemCarrinhoAtual", carrinhoAtual);

      const dadosPedido = {};
      this.$store.dispatch("setDadosPedido", dadosPedido);

      localStorage.removeItem("pedidoaberto");
    },
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      // this.focusElementStyle = {
      //   width: `${target.offsetWidth}px`,
      //   height: `${target.offsetHeight}px`,
      //   transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      // };

      // this.cardError = "";
    },
    validateCard(field) {
      switch (field) {
        case "cardNumber":
          this.getBrand();
          if (!this.validateCreditCardNumber(this.unformattedCardNumber)) {
            this.cardError = "Ops, cartão inválido";
            let target = this.$refs[field];
            target.borderColor = "red";
            return;
          }
          this.cardError = "";

          break;

        default:
          break;
      }
    },
    blurInput(e) {
      let targetRef = e.target.dataset.ref;
      this.validateCard(targetRef);

      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

body {
  background: #ddeefc;
  font-size: 16px;
}
.saveCardSelect {
  margin-top: 45px;
  display: flex;
  justify-content: flex-start;
}
.saveCardSelect label {
  margin-left: 7px;
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.wrapper {
  display: flex;
  width: 100%;
}

.cardCollumnItems {
  margin-top: 20px;
}
.error {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 500;
  color: red;
  width: 100%;
  display: block;
  user-select: none;
}

.btn-enviar {
  width: 100%;
  padding: 8px;
  background: $brand-primary;
  color: #fff;
  border: 0;
  margin-top: 15px;
  font-weight: 600;
  border-radius: 6px;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover {
    background: #006534;
  }
}

.card-form {
  width: 100%;
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      // margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 70px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }
  &__inner {
    width: 50%;
    box-sizing: border-box;
    // padding-left: 40px;
    // padding-right: 40px;
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.card-item {
  width: 100%;
  height: 179px;
  position: relative;
  z-index: 2;

  .card-item__cover {
    background: radial-gradient(
      ellipse farthest-corner at 0 140%,
      #42a06b 0%,
      #276f46 70%,
      #246742 70%
    );
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 152, 64, 0.5);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 30px;
    @media screen and (max-width: 480px) {
      width: 30px;
    }
    @media screen and (max-width: 360px) {
      width: 30px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 70%;
    object-fit: contain;
    max-height: 70%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 55%;
    max-width: calc(100% - 85px);
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(0, 152, 64, 0.45);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 16px;
    display: inline-block;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 10px;
    display: inline-block;
    &.-active {
      width: 5px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 12px;
    margin-left: auto;
    display: inline-flex;
    white-space: nowrap;
    flex-shrink: 0;
    justify-content: flex-end;
    cursor: pointer;
    width: 40%;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #286d46;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(0, 152, 64, 0.45);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  width: 290px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #000;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #286d46;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: $brand-primary;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(0, 152, 64, 0.45);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.15s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.15s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.15s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.15s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(0, 152, 64, 0.45);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(0, 152, 64, 0.45);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(0, 152, 64, 0.45);
    }
  }
}

/** Responsividade */

// @media (max-width: 1234px) {
//   .card-list {
//     width: 40%;
//   }
//   .card-form__inner {
//     width: 60%;
//   }
// }

@media (max-width: 1030px) {
  // .card-list {
  //   width: 40%;
  // }
  .card-form__inner {
    width: 45%;
    // padding-left: 25px;
    // padding-right: 25px;
  }
}

@media (max-width: 1000px) {
  .card-form {
    border-bottom: 1px solid #e5e5e5;
  }
}

@media (max-width: 800px) {
  // .card-list {
  //   width: 40%;
  // }
  .card-form__inner {
    // width: 60%;
    // padding-left: 25px;
    // padding-right: unset;
  }
  // .card-item {
  //   height: 150px;
  // }
}

@media (min-width: 700px) {
  .saveCardSelect {
    display: flex;
  }
  .saveCardSelectMobile {
    display: none;
  }
}
@media (max-width: 700px) {
  .card-form {
    flex-direction: column;
  }
  .card-list {
    // width: 100%;
    // align-items: center;
    // padding: 0 0 15px 0px;
    display: none;
  }
  .card-item {
    // width: 55%;
    // height: 172px;
  }
  .card-form__group {
    width: 100%;
  }
  .saveCardSelect {
    display: none;
  }
  .saveCardSelectMobile {
    display: flex;
  }
  .card-form__inner {
    margin-top: 35px;
    // padding-left: 0;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .card-input__label {
    margin-left: 9px;
  }
}

@media (max-width: 536px) {
  .card-item {
    // width: 70%;
  }
}
@media (max-width: 470px) {
  .card-item {
    // width: 90%;
  }
}
@media (max-width: 350px) {
  .card-item__number {
    font-size: 16px;
    margin-bottom: unset;
    padding: unset;
  }
}
@media (max-width: 325px) {
  .card-item__wrapper {
    padding: 16px 5px;
  }
}
</style>
