<template>
  <div v-if="!cookieConsent">
    <div class="cookie-consent">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="cookie-consent-inner">
              <div class="cookie-consent-inner-text">
                <p>
                  <b>Usamos cookies para melhorar a experiência do usuário.</b>
                  <br />
                  Ao acessar ou usar o site, você concorda com a nossa
                  <a :href="privacyPolicyUrl">Política de Privacidade</a>.
                </p>
              </div>
              <div class="cookie-consent-inner-button">
                <div class="cookie-consent-inner-button-inner">
                  <button class="btn btn-lemon" @click="acceptCookies">
                    Aceitar e fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /create cookie consent component -->
  </div>
</template>

<script>
import cookie from "vue-cookie";

export default {
  name: "CookieConsent",
  components: {},
  data() {
    return {
      cookieConsent: false,
      privacyPolicyUrl: process.env.VUE_APP_POLITICA_PRIVACIDADE_URL || "",
    };
  },
  mounted() {
    const cookieConsent = cookie.get("cookieConsent");
    if (cookieConsent) {
      this.cookieConsent = true;
    }
  },
  methods: {
    acceptCookies() {
      this.cookieConsent = true;
      cookie.set("cookieConsent", true, 365);
    },
  },
};
</script>

<style lang="scss" scoped>
// floating cookie consent alert with rounded corners
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #333;
  padding: 15px 5px;
  color: white;
  width: 500px;
  margin: 90px 18px;
  border-radius: 6px;
  max-width: calc(100vw - 36px);
}

// mobile only
@media (max-width: 767px) {
  .cookie-consent {
    button {
      width: 100%;
    }
  }
}
</style>
