<template>
  <div>
    <template v-if="!removeitem">
      <div
        v-if="quantidade > 0 && !loading"
        :class="{
          'item-qty-simple': simple,
          'item-qty': !simple,
          square: squareType,
          round: roundType
        }"
      >
        <button
          v-if="showButtons"
          :disabled="quantidade < 1 || loadingProduto"
          class="button-minus"
          @click="changeItemQty('minus', item)"
        >
          <i
            class="fa"
            :class="{
              'fa-spinner fa-spin': loadingProduto,
              'fa-minus': !loadingProduto
            }"
            style="font-size: 12px !important"
            :style="{ opacity: quantidade == 0 ? '.5' : null }"
          ></i>
        </button>
        <input
          v-model.number.lazy="quantidade"
          :type="showButtons ? 'number' : 'text'"
          :disabled="loadingProduto"
          class="qty"
          min="0"
          @change="changeItemQty(null, item)"
          @keydown.9.capture.prevent.stop
          @keyPress.9.capture.prevent.stop
          @keyPress.16.capture.prevent.stop
          @keydown.16.capture.prevent.stop
          @keyup="qtdFocus"
          @click="clickInput(item)"
          @focus="$event.target.select()"
          @keypress="isNumber($event)"
        />
        <button
          v-if="showButtons"
          :disabled="loadingProduto"
          :class="
            squareType
              ? 'squareButton button-plus '
              : 'roundButton button-plus '
          "
          @click="changeItemQty('plus', item)"
        >
          <i
            :class="{
              'fa fa-spinner fa-spin': loadingProduto,
              'fa fa-plus': !loadingProduto
            }"
            style="font-size: 12px !important"
          ></i>
        </button>
      </div>
      <template v-else>
        <button
          class="btn-addItem"
          :class="{ 'square-buy': squareType }"
          :disabled="loading"
          @click="changeItemQty('plus', item)"
        >
          <i
            :class="{
              'fa fa-spinner fa-spin': loading,
              'fa fa-plus': !loading
            }"
          ></i>
          <span v-if="!loading"> Adicionar</span>
          <span v-else> Carregando</span>
        </button>
      </template>
    </template>
    <div v-else :class="{ 'item-qty-simple': simple, 'item-qty': !simple }">
      <button
        class="button-minus"
        :disabled="loadingProduto || loading"
        @click="changeItemQty('removeItem', item)"
      >
        <i class="fa fa-times text-danger"></i>
      </button>
    </div>
    <span
      v-if="canRemoveAll && !removeitem"
      title="Remover produto do carrinho"
      class="item-remove"
      @click="changeItemQty('removeItem', item)"
      >remover</span
    >
  </div>
</template>

<script>
import MinusIcon from "vue-feather-icons/icons/MinusIcon";
import PlusIcon from "vue-feather-icons/icons/PlusIcon";
import _debounce from "lodash/debounce";
import bus from "@/utils/events/bus";

export default {
  components: {
    PlusIcon,
    MinusIcon
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    simple: {
      default: false,
      type: Boolean
    },
    removeitem: {
      default: false,
      type: Boolean
    },
    showButtons: {
      default: true,
      type: Boolean
    },
    canRemoveAll: {
      default: false,
      type: Boolean
    },
    squareType: {
      default: false,
      type: Boolean
    },
    roundType: {
      default: true,
      type: Boolean
    },
    isCartOrCheckout: {
      default: false,
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    },
isCartOperation: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      loadingProduto: false,
      dBouncerVet: [],
      campanhaStatus: []
    };
  },
  computed: {
    orderData() {
      return this.$store.getters.dadosPedido;
    },

    quantidade: {
      get() {
   if (this.isCartOperation) {
          const item = this.$store.getters.carrinhoAtual;
          const produto = item.find(
            (i) => this.item.cod_produto == i.cod_produto
          );
          if (produto) {
            this.item.qtd = produto.qtd;
            return produto.qtd;
          }
          this.item.qtd = 0;
          return 0;
        }
        return this.item.qtd;
      },
 set(value) {
        this.item.qtd = value;
      },
    }
  },
  watch: {},
  mounted() {
    bus.$on("loadingProduto", (params) => {
      this.loadingProduto = params;
    });
  },
  methods: {
    isNumber(evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    /**
     * Função chamada no click do botão de adicionar ou remover item da quantidade do carrinho
     */

    handleButtonClick(type, row) {
      row.qtd = this.quantidade;
      if (type == "minus") {
        row.qtd == 0 ? (row.qtd = 0) : (row.qtd -= 1);
      } else if (type == "add") {
        row.qtd == "" ? (row.qtd = 1) : (row.qtd += 1);
      }

      // Atualiza o store do carrinho
      this.shouldStoreUpdate(row);
    },

    /**
     * Method changeItemQty
     * Responsável por atualiar quantidade de item selecionado.
     */
    async changeItemQty(type, item) {
      const that = this;
      if (!item) return;
      // if (!item.qtd) item.qtd = this.quantidade;
      const oldQtd = item.qtd;
      // eslint-disable-next-line no-param-reassign
      item.status = 1;

      // eslint-disable-next-line default-case
      switch (type) {
        case "minus":
          if (item.qtd > 1 || !this.canRemoveAll) {
            item.qtd--;
          }

          break;
        case "plus":
          item.qtd++;
          /// add to cart animation
          break;
        case "removeItem":
          item.qtd = 0;
          break;
      }

if (!this.isCartOperation) {
        console.log(item);
        this.$emit("quantity-changed", { item, type });
        return;
      }

      item.pedido_id = this.orderData.pedidoId;

      this.$store.dispatch("addItemCarrinhoAtual", item);
      const dFunc = _debounce(async function (item) {
        this.loadingProduto = true;
        try {
          // Verifica se está buscando do banco ou se está setando um novo item

          let atualizaProduto = false;

          try {
            const response = await dpcAxios
              .connection()
              .post(
                process.env.VUE_APP_ECOMMERCE + api.env.ADICIONA_PRODUTO_EC,
                {
                  pedido_id: item.pedido_id,
                  itens: [
                    {
                      produto_id: item.cod_produto,
                      qtd: item.qtd,
                      vlrproduto: item.valor,
                      vlrdesconto: item.vlrdesconto,
                      vlrst: item.vlrst,
                      status: item.status
                    }
                  ]
                }
              );

            if (response.data.error == 1) {
              throw new Error(response.data.message);
            }

            if (response.data.qtdestoque) {
              if (item.qtd > response.data.qtdestoque) {
                // bus.$emit("show-notification", [
                //   "warn",
                //   "Desculpe.",
                //   `Este produto só possui ${response.data.qtdestoque} itens em estoque.`
                // ]);

                bus.$emit("show-notification", [
                  "warn",
                  "Desculpe.",
                  "Reajustamos a quantidade, pois o número solicitado não está disponível."
                ]);

                item.qtd = response.data.qtdestoque;
                item.qtdestoque = response.data.qtdestoque;
                atualizaProduto = true;
                that.$store.dispatch("addItemCarrinhoAtual", item);
              }
            }
          } catch (e) {
            item.qtd = oldQtd;

            console.error(e);
            bus.$emit("show-notification", [
              "warn",
              "Desculpe.",
              e.message || "Ocorreu um erro ao adicionar o item no carrinho."
            ]);
          }

          // console.log(response.data.itens);

          // that.$store.dispatch("addItemCarrinhoAtual", item);

          if (atualizaProduto) {
            const payload = {
              pedido_id: item.pedido_id,
              itens: [
                {
                  produto_id: item.cod_produto,
                  qtd: item.qtd,
                  vlrproduto: item.valor,
                  vlrdesconto: item.vlrdesconto,
                  vlrst: item.vlrst,
                  status: item.status
                }
              ]
            };
            const url =
              process.env.VUE_APP_ECOMMERCE + api.env.ADICIONA_PRODUTO_EC;
            const response = await dpcAxios.connection().post(url, payload);
          }
          that.$store.dispatch("addItemCarrinhoAtual", item);

          if (that.isCartOrCheckout) {
            that.$store.dispatch("searchDiscountProducts");
          }
        } catch (err) {
          // TODO: tratar aqui né!
          console.error(err);
          return;
        }

        this.loadingProduto = false;
      }, 400);

      let dItem = this.dBouncerVet.find(
        (i) => i.produto_id == item.cod_produto
      );

      if (typeof dItem === "undefined") {
        dItem = {
          produto_id: item.cod_produto,
          dbouncer: dFunc
        };

        this.dBouncerVet.push(dItem);
      }

      dItem.dbouncer(item);
      // this.checkOrderDiscounts(this.orderData.pedidoId, this.orderData.razao);
      // this.debouncerCli(item);
    },
    /**
     * Method qtdFocus
     * Responsável por emitir evento de que input está focado
     */
    qtdFocus(event) {
      this.$emit("input-focus", event);
    },
    /**
     * Method clickInput
     * Responsa´vel por emitir evento de click no input de quantidade
     */
    clickInput(event) {
      this.$emit("input-click", event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

@media (max-width: 525px) {
  .qtd-container {
    .item-qty {
      .qty {
        max-width: 100%;
        width: 100% !important;
      }
    }
  }
}

button.btn-addItem {
  width: 100%;
  padding: 8.5px 12px;
  font-size: 12px;
  background: var(--brand-primary, $brand-primary);
  width: 100%;
  color: white;

  font-weight: bold;
  border: none;
  // border-radius: 6px;
}
button.btn-addItem:hover {
  background: darken($brand-primary, 10);
}

.item-qty,
.item-qty-simple {
  position: relative;
}

.item-qty-simple {
  white-space: nowrap;
  flex: 0 1 auto;
  text-align: left;
  color: #666;
  padding-top: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  // -ms-flex-direction: column;
  // flex-direction: column;
  // -ms-flex-item-align: center;
  align-self: center;
  align-items: center;

  .button-plus {
    border-radius: 0px 5px 5px 0px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .button-minus {
    border-radius: 5px 0px 0px 5px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Webkit browsers like Safari and Chrome */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .qty {
    max-width: 48px;
    outline: 0 !important;
    padding: 0;
    text-align: center;
    font-size: 15px;
    border: 1px solid #eeeeee;
    background-color: transparent;
    border-radius: 3px;
    margin: 2px 10px 2px 10px;

    &:focus {
      border-color: var(--brand-primary, $brand-primary);
      border-width: 1px;
    }
  }

  .button-minus,
  .button-plus {
    display: flex;
    align-items: center;
    border: 0;
    margin: 0;
    padding: 0;
    background: transparent;
  }
}
.round {
  // width: 103px;
  justify-content: center;
  border-radius: 15px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.square {
  border-radius: 6px;
  display: flex;
  width: 126.97px;
  height: 43px;
  padding-left: 15px;
  justify-content: space-between;
  padding-right: 15px;
}

.item-qty {
  background-color: var(--brand-primary, $brand-primary);

  display: flex;

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Webkit browsers like Safari and Chrome */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .qty {
    border-radius: 0;
    max-width: 45px;
    outline: 0 !important;
    color: white;
    box-shadow: none !important;
    border: none;
    font-size: 14px;
    padding: 0px 0px;
    font-weight: bold;
    background-color: transparent;
    text-align: center;

    &:focus {
      // ADICIONAR BARRINHA EM BAIXO PRA COLCOAR O VALOR DIGITANDO
    }
  }

  .squareButton {
    .iconQtd {
      font-weight: bold;
      width: 2em;
      height: 2em;
    }
  }

  .button-minus,
  .button-plus {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    font-size: 0.85rem;
    padding-top: 9px;
    padding-bottom: 9px;
    color: white;
  }
}
.item-remove {
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  color: #666;

  &:hover {
    text-decoration: underline;
  }
}
.square-buy {
  padding: 13px 36px !important;
}
</style>
