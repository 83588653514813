<template>
  <div>
    <section id="bar-top">
      <div class="container infoContainer">
        <div class="w-full content">
          <ul
            class="lista-top justify-center list-none flex flex-row w-full items-center faqs"
          >
            <li class="mr-6">
              <router-link class="flex flex-row items-center" to="/atendimento">
                <i
                  class="fa fa-phone icon-bar-top p-4 mr-2"
                  aria-hidden="true"
                ></i>
                <span class="text-white text-lg text-lg">Fale conosco</span>
              </router-link>
            </li>
            <li class="mr-6">
              <router-link
                class="flex flex-row items-center"
                tag="a"
                :to="{ name: 'Atendimento' }"
              >
                <i
                  class="fa fa-question icon-bar-top p-4 mr-2"
                  aria-hidden="true"
                ></i>
                <span class="text-white text-lg">Dúvidas frequentes</span>
              </router-link>
            </li>
            <li class="mr-6">
              <router-link
                v-if="isAuthenticated"
                class="flex flex-row items-center"
                tag="a"
                to="/minha-conta/pedidos"
              >
                <i
                  class="fa fa-archive icon-bar-top p-4 mr-2"
                  aria-hidden="true"
                ></i>
                <span class="text-white text-lg">Meus pedidos</span>
              </router-link>
              <a v-else class="flex flex-row items-center" :href="logginUrl">
                <i
                  class="fa fa-archive icon-bar-top p-4 mr-2"
                  aria-hidden="true"
                ></i>
                <span class="text-white text-lg">Meus pedidos</span>
              </a>
            </li>
          </ul>
          <div class="userInfos" @click="showModalRazao = true">
            <RazaoCardInfo
              v-if="isAuthenticated"
              ref="RazaoCardInfo"
              @listagemRedes="listagemRedes = $event"
            />
          </div>
        </div>
      </div>
    </section>
    <modal-razao
      v-if="isAuthenticated && (showModalRazao || !selectUnidade)"
      :listagem-redes="listagemRedes"
      :select-unidade="selectUnidade"
      @selectUnidade="handleSelectUnidade"
      @close="showModalRazao = false"
    />
  </div>
</template>

<script>
import * as global from "@/app/global";
import RazaoCardInfo from "@/components/razao-card-info/RazaoCardInfo.vue";
import ModalRazao from "@/components/root/modal/ModalRazao.vue";

export default {
  name: "BarTop",
  components: {
    RazaoCardInfo,
    ModalRazao,
  },
  data() {
    return {
      showModalRazao: false,
      infoUsuario: {},
      listagemRedes: [],
    };
  },
  computed: {
    logginUrl() {
      return `${process.env.VUE_APP_CLIENTE}?rd=minha-conta/pedidos`;
    },
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    getTelTelevenda() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_televenda
      );
    },
    getTelSac() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_sac
      );
    },
    selectUnidade() {
      return Number(this.$store.getters.clienteAtual);
    },
  },
  methods: {
    handleSelectUnidade(redeId) {
      this.$refs.RazaoCardInfo.selectUnidade = redeId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
@import "~@/assets/css/global.css";

#bar-top {
  background-color: var(--brand-primary, $brand-primary);
  min-height: 35px;
  line-height: 35px;
  color: #fff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 0 25px;
  z-index: 1;
  position: relative;
  .infoContainer {
    max-width: initial;
    padding: 0;
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-top: 10px;
      height: 45px;
      .userInfos {
        display: flex;
        justify-content: center;
        color: white;
        gap: 20px;
        grid-column: 3;
        justify-self: flex-end;
        font-size: 11.75px;
      }
      ul.faqs {
        grid-row: 1;
        grid-column: 2;
      }
    }
  }
}
.icon-bar-top {
  font-size: 10px;
  background-color: var(--brand-accent, $brand-accent);
  color: var(--brand-primary, $brand-primary);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.scroll-hidden {
  // margin-top: -35px;
  // margin-top: -85px;
  margin-top: -34px;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 1000px) and (max-width: 1550px) {
  #bar-top {
    .infoContainer {
      .content {
        display: flex;
        justify-content: space-between;

        .faqs {
          width: initial;
          padding-left: 0;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #bar-top {
    display: none;
  }
}
</style>
