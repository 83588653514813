<template>
  <div v-if="1" class="bannerHeader">
    <div class="titleBanner">
      <span>Uma Seleção de ofertas pensada em você!</span>
      <h3>
        Ganhe <span>descontos exclusivos</span> completando combos de produtos.
      </h3>
    </div>
    <img
      class="imgBanner"
      src="@/assets/img/campanhas/img-banner-principal.svg"
    />
  </div>
  <div v-else>
    <img
      style="width: 100%; border-radius: 6px; margin-bottom: 15px"
      :src="srcImage"
    />
  </div>
</template>

<script>
export default {
  name: "BannerPrincipalCampanha",
  props: {
    paramsCampanha: {
      type: Object,
    }
  },
  data() {
    return {
      srcImage: "",
    };
  },
  computed: {
    campanhaStatus() {
      return this.$store.getters.campanhaStatus;
    },
  },

  watch: {
    campanhaStatus(newValue) {
      this.getDataTimeline();
    },
  },

  created() {
    this.$store.dispatch("getCampanhasDisponiveis", this.paramsCampanha);
  },
  methods: {
    getDataTimeline() {
      const campanhaId = this.$route.query.campanhaId;

      if (!this.campanhaStatus[campanhaId]) return;

      const campanhaStatus = this.campanhaStatus[campanhaId];

      this.srcImage = campanhaStatus.bannerSrc;
    },
  },
};
</script>

<style lang="scss" scoped>
.bannerHeader {
  height: 350px;
  width: 100%;
  background: var(--brand-secondary, $brand-secondary);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  margin-bottom: 15px;
  .titleBanner {
    color: #ffffff;
    span {
      font-weight: bold;
      font-size: 16px;
    }
    h3 {
      font-size: 36px;
      font-weight: bold;
      span {
        color: #92e3a9;
        font-size: 36px;
      }
    }
  }
}

@media (max-width: 968px) {
  .bannerHeader {
    padding: 35px;
  }
}
@media (max-width: 728px) {
  .bannerHeader {
    padding: 10px;
    .titleBanner {
      span {
        font-size: 14px;
      }
      h3 {
        font-size: 22px;
        span {
          font-size: 22px;
        }
      }
    }
    .imgBanner {
      width: 40%;
    }
  }
}
@media (max-width: 548px) {
  .bannerHeader {
    padding: 10px;
    flex-direction: column;
    text-align: center;
    .titleBanner {
      span {
        font-size: 14px;
      }
      h3 {
        font-size: 19px;
        span {
          font-size: 19px;
        }
      }
    }
    .imgBanner {
      width: 40%;
    }
  }
}
@media (max-width: 400px) {
  .bannerHeader {
    padding: 10px;
    flex-direction: column;
    text-align: center;
    .titleBanner {
      span {
        font-size: 12px;
      }
      h3 {
        font-size: 18px;
        span {
          font-size: 18px;
        }
      }
    }
    .imgBanner {
      width: 65%;
    }
  }
}
</style>
