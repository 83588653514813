import { render, staticRenderFns } from "./ModalClienteInfo.vue?vue&type=template&id=7c20173c&scoped=true&"
import script from "./ModalClienteInfo.vue?vue&type=script&lang=js&"
export * from "./ModalClienteInfo.vue?vue&type=script&lang=js&"
import style0 from "./ModalClienteInfo.vue?vue&type=style&index=0&id=7c20173c&prod&lang=scss&scoped=true&"
import style1 from "./ModalClienteInfo.vue?vue&type=style&index=1&id=7c20173c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c20173c",
  null
  
)

export default component.exports