import * as dpcAxios from "@/dpcAxios";
import Bus from "@/utils/events/bus";
import router from "@/router";
import state from "./state";

export const setIsAuthenticated = (context, payload) => {
  context.commit("setIsAuthenticated", payload);

  if (state.isDpcMobileWebView) {
    // Post a message to the WebView when authentication state change.
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "auth_state",
          isAuthenticated: payload,
        })
      );
  }
};

export const setInformacaoGlobal = (context, payload) => {
  context.commit("setInformacaoGlobal", payload);
};

export const setToken = (context, payload) => {
  context.commit("setToken", payload);
};

export const logoff = (context, payload) => {
  context.commit("logoff", payload);
};

export const setUser = (context, payload) => {
  context.commit("setUser", payload);
};

function debounce(func, timeout = 600) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

// TODO: melhorar isso, ficou bem esquisito. Manter tudo simples, sempre.
/**
 * Método checkOrderDiscounts
 * Responsável por verificar se o item adicionado ganhou algum desconto
 * e salvar as informações das campanhas no store
 */

async function checkOrderDiscounts(context) {
  // if (router.history.current.name != "campanhaMain") return

  if (!state.campanhasDisponiveis.length) return;

  const { campanhasDisponiveis } = state;
  context.commit("setCampanhaStatus", campanhasDisponiveis);
}

export async function getCampanhasDisponiveis(context, payload) {
  const { dadosPedido } = context.getters;

  if (!dadosPedido.razao) return;

  const preco = {
    pedido_id: dadosPedido.pedidoId,
    cliente_id: dadosPedido.razao,
    tabvnd_id: dadosPedido.tabvnd,
  };

  const tabvnd_id = dadosPedido.tabvnd;

  const hascasadinha = {
    empresa: {
      id: dadosPedido.unidade,
    },
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const hasprodbloq = {
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const columns = { hascasadinha, hasprodbloq };

  const { limit, offset, searchTerm } = payload;

  const params = {};

  params.cliente_id = dadosPedido.razao;
  params.paramsProduct = {
    preco,
    tabvnd_id,
    columns,
  };

  params.limit = limit;
  params.offset = offset;
  params.searchTerm = searchTerm;

  if (payload.clear) {
    context.commit("setCampanhasDisponiveis", []);
  }

  try {
    const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_CAMPANHAS}`;
    const url1 = `${process.env.VUE_APP_ECOMMERCE}${api.env.VERIFICA_DESCONTOS_PEDIDO}/${dadosPedido.pedidoId}`;
    const { data } = await dpcAxios.connection().post(url, params);
    // const { data: data1 } = await dpcAxios.connection().post(url1, params);

    if (!data.error) {
      if (data.length <= 0 && payload.clear) {
        if (!searchTerm) {
          context.commit("setCampanhasDisponiveis", "show_not_found");
        } else {
          context.commit("setCampanhasDisponiveis", "search_not_found");
        }
      } else {
        if (payload.clear) {
          context.commit("setCampanhasDisponiveis", data);
        } else {
          context.commit(
            "setCampanhasDisponiveis",
            state.campanhasDisponiveis.concat(data)
          );
        }
      }

      if (data.length == 0) {
        context.commit("setHasMoreCampaignsToShow", false);
      } else {
        context.commit("setHasMoreCampaignsToShow", true);
      }
      discounts(context);
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err.message);
    Bus.$emit("show-notification", ["error", "Desculpe.", `${err.message}`]);
  }
}

export async function searchProductsBonus(context, payload) {
  const { dadosPedido } = context.getters;

  if (!dadosPedido.razao || !dadosPedido.pedidoId) return;

  const preco = {
    pedido_id: dadosPedido.pedidoId,
    cliente_id: dadosPedido.razao,
    tabvnd_id: dadosPedido.tabvnd,
  };

  const tabvnd_id = dadosPedido.tabvnd;

  const hascasadinha = {
    empresa: {
      id: dadosPedido.unidade,
    },
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const hasprodbloq = {
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const columns = { hascasadinha, hasprodbloq };
  const params = {};
  params.cliente_id = dadosPedido.razao;
  params.paramsProduct = {
    integracao_id: payload,
    preco,
    tabvnd_id,
    columns,
  };

  try {
    const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_PRODUTOS_BONUS}`;
    const { data } = await dpcAxios.connection().post(url, params);
    context.commit("searchProductsBonus", data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    // Bus.$emit("show-notification", [
    //   "error",
    //   "Desculpe.",
    //   `ERRO NA BUSCA DE DESCONTO`,
    // ]);
  }
}

const discounts = debounce((context) => checkOrderDiscounts(context));

export const setUsuarioInfo = (context, payload) => {
  context.commit("setUsuarioInfo", payload);
};

export const setUserShouldResetPassword = (context, payload) => {
  context.commit("setUserShouldResetPassword", payload);
};

export const setDadosPedido = (context, payload) => {
  context.commit("setDadosPedido", payload);
  if (state.isDpcMobileWebView) {
    // Post a message to the WebView to open the URL in the stack navigation
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "set_dadospedido",
          data: payload,
        })
      );
  }
};

export const addItemCarrinhoAtual = (context, payload) => {
  context.commit("addItemCarrinhoAtual", payload);
  discounts(context);
};

export const setCampanhaStatus = (context) => {
  discounts(context);
};

export const paymentData = (context, payload) => {
  context.commit("paymentData", payload);
};

export const removeStoredCard = (context, payload) => {
  context.commit("removeStoredCard", payload);
};

export const totalProduct = (context, payload) => {
  context.commit("totalProduct", payload);
};

export const faturamentoData = (context, payload) => {
  context.commit("faturamentoData", payload);
};

export const saveCards = (context, payload) => {
  context.commit("saveCards", payload);
};

export const savedCardSelected = (context, payload) => {
  context.commit("savedCardSelected", payload);
};

export const cvvCardSave = (context, payload) => {
  context.commit("cvvCardSave", payload);
};
///
export const globalAppLoading = (context, payload) => {
  context.commit("globalAppLoading", payload);
};

export const cardPaymentError = (context, payload) => {
  context.commit("cardPaymentError", payload);
};

export const setClienteAtual = (context, payload) => {
  context.commit("setClienteAtual", payload);
  localStorage.setItem("clienteAtual", payload);
};

export const searchDiscountProducts = (context, payload) => {
  context.commit("searchDiscountProducts", payload);
};

export const setShowLoadingModal = ({ commit }, payload) => {
  let id = payload[1];
  let value = payload[0];

  if (!id) id = 1;

  if (value) {
    commit("addLoadingModalId", id);
  } else {
    commit("removeLoadingModalId", id);
  }

  let isLoading;
  if (!value && !state.loadingModalIds.length) {
    commit("setShowLoadingModal", false);
    isLoading = false;
  } else {
    commit("setShowLoadingModal", true);
    isLoading = true;
  }

  if (state.isDpcMobileWebView) {
    // Post a message to the WebView to open the URL in the stack navigation
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "set_loading",
          loading: isLoading,
        })
      );
  }
};
