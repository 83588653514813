<template>
  <div class="product-variant-list">
    <div v-if="loading" class="product-variant-list__loading">
      <i class="mdi mdi-loading fa-spin fa-4x"></i>
    </div>
    <product-variant
      v-for="(variante, index) in productVariant.variantes"
      v-if="(isPreview && index == 0) || !isPreview"
      :key="`${index}`"
      :is-first-variant="index == 0"
      :variant-attributes="variante"
      :selected-variants="variantSelected"
      :number-of-variants="numberOfVariants"
      :is-preview="isPreview"
      :current-product-id="currentProductId"
      @variantSelected="
        isUserSelected = true;
        handleVariantSelected(variante.id, $event, true);
      "
      @clearVariantSelected="handleClearVariantSelected(variante.id)"
      @imagePreview="$emit('imagePreview', $event)"
    ></product-variant>
  </div>
</template>

<script>
import Vue from "vue";
import ProductVariant from "./ProductVariant.vue";

export default {
  name: "ProductVariantList",
  components: {
    ProductVariant,
  },
  props: {
    productVariant: {
      type: Object,
      default: () => {},
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    currentProductId: {
      type: Number / String,
      default: 0,
      rquired: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      variantSelected: [],
      isUserSelected: false,
    };
  },
  computed: {
    numberOfVariants() {
      return this.productVariant.variantes.length;
    },
  },
  watch: {
    variantSelected(newValue) {
      if (!newValue) return;

      this.$emit(
        "variant-selected-description",
        newValue
          .filter((item) => item.descricao)
          .map((item) => item.descricao)
          .join(" ")
      );
    },
  },
  created() {
    const queryParams = this.$route.query;

    let hasAttrSelected = [];

    // if (this.isPreview) return;

    this.productVariant.variantes.forEach((variante) => {
      if (hasAttrSelected[variante.id]) {
        return;
      }

      let selectedAttr = null;

      variante.atributos.some((atributo) => {
        if (
          atributo.produtos.some(
            (produto) => produto.integracao_id == this.currentProductId
          )
        ) {
          selectedAttr = atributo;
          return true;
        }

        if (
          Object.keys(queryParams).some(
            (param, index) =>
              typeof queryParams[param] === "string" &&
              queryParams[param].toLowerCase() == atributo.descricao &&
              atributo.descricao.toLowerCase()
          )
        ) {
          selectedAttr = atributo;
          return true;
        }

        return false;
      });

      if (!selectedAttr) {
        selectedAttr = variante.atributos[0];
      }

      this.handleVariantSelected(variante.id, selectedAttr);
      hasAttrSelected[variante.id] = true;
    });
  },
  methods: {
    handleClearVariantSelected(variantId) {
      Vue.set(this.variantSelected, variantId, []);
    },
    redirectProductDetail(productId) {
      let query = {};

      this.productVariant.variantes.forEach((variante) => {
        this.variantSelected.forEach((element, i) => {
          if (i == variante.id) {
            query = {
              ...query,
              ...{
                [variante.descricao.toLowerCase()]:
                  element.descricao.toLowerCase(),
              },
            };
          }
        });
      });

      this.$router.push({
        name: "produtoRota",
        params: { codigo: productId, isVariant: !this.isPreview },
        query,
      });
    },
    handleVariantSelected(variantId, attribute, redirectIfIsPreview = false) {
      Vue.set(this.variantSelected, variantId, attribute);

      const selectedProduct = this.getSelectedProduct();

      this.$emit("product-selected", selectedProduct);
      if (selectedProduct) {
        if (this.isPreview) {
          if (redirectIfIsPreview) {
            this.redirectProductDetail(selectedProduct);
          }
        } else if (selectedProduct != this.currentProductId) {
          this.redirectProductDetail(selectedProduct);
        }
        return;
      }

      if (
        process.env.VUE_APP_PRODUCT_VARIATION_MODE == 1 &&
        (!this.isUserSelected || attribute.produtos.length == 0)
      )
        return;

      let firstVariantId =
        process.env.VUE_APP_PRODUCT_VARIATION_MODE == 1
          ? this.productVariant.variantes[0].id
          : -1;

      const productVariantsRemaining = this.productVariant.variantes.filter(
        (x) => x.id != variantId && x.id != firstVariantId
      );

      const attributeProductMap = attribute.produtos.reduce((map, product) => {
        map[product.id] = product;
        return map;
      }, {});

      let foundAttribute = [];
      // let foundVariantId;
      productVariantsRemaining.some((variant) => {
        variant.atributos.some((atributo) => {
          if (
            atributo.produtos.some((product) => attributeProductMap[product.id])
          ) {
            foundAttribute.push({ atributo, variantId: variant.id });
            // foundVariantId = variant.id;
            // return true;
            if (process.env.VUE_APP_PRODUCT_VARIATION_MODE != 1) {
              this.handleClearVariantSelected(variant.id);
            } else {
              return true;
            }
          }
        });
        if (process.env.VUE_APP_PRODUCT_VARIATION_MODE == 1) {
          if (foundAttribute) return true;
        }
      });

      if (process.env.VUE_APP_PRODUCT_VARIATION_MODE == 1) {
        if (foundAttribute.length > 0) {
          this.handleVariantSelected(
            foundAttribute[0].variantId,
            foundAttribute[0].atributo,
            redirectIfIsPreview
          );
        }
      }
      // console.log("foundAttribute", foundAttribute);
      // if (foundAttribute) {
      //   this.handleVariantSelected(
      //     foundVariantId,
      //     foundAttribute,
      //     redirectIfIsPreview
      //   );
      // }
      // todo: faz como ML, se selecionar uma variante que não tem outra dispnível, limpa as outras, ao invés de selecionar a primeira, como estamos fazendo agora.
      // Usa a funçao  this.handleClearVariantSelected(foundVariantId);
      // É necessário corrigir algumas coisas dentro da variação, pois atualmente irá gerar erro ao limpar a variante selecionada.
    },

    getSelectedProduct() {
      const produtosSelecionados = Object.values(this.variantSelected).map(
        (attribute) => attribute.produtos
      );
      const produtosOcurrences = produtosSelecionados
        .flat()
        .reduce((map, produto) => {
          if (!map || !produto) return map;
          if (map[produto.integracao_id]) {
            map[produto.integracao_id] += 1;
          } else {
            map[produto.integracao_id] = 1;
          }
          return map;
        }, {});

      return Object.keys(produtosOcurrences).find(
        (key) => produtosOcurrences[key] == this.numberOfVariants
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.product-variant-list {
  margin-top: 5px;
  position: relative;

  &__loading {
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;
    i {
      color: $brand-primary;
      position: absolute;
      display: flex;
    }
  }
}
</style>
