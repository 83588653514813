<template>
  <v-select
    :options="attributes"
    label="descricao"
    class="product-variant-dropdown"
    :clearable="false"
    placeholder="Selecione uma opção..."
    :value="value"
    @input="$emit('input', $event)"
  >
    <template #option="{ descricao, imagem }">
      <div class="product-variant-dropdown__img_wrapper">
        <img
          :src="imagem"
          :alt="descricao"
          onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
        />
      </div>
      <span>{{ descricao }}</span>
    </template>
    <template #selected-option="{ descricao, imagem }">
      <div class="product-variant-dropdown__img_wrapper">
        <img
          :src="imagem"
          :alt="descricao"
          onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
        />
      </div>
      <span>{{ descricao }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "ProductVariantDropdown",
  props: {
    attributes: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    value(value) {
      console.log(value);
    },
  },
  methods: {
    input(event) {
      console.log(event);
    },
  },
};
</script>

<style lang="scss">
.product-variant-dropdown .vs__search::placeholder,
.product-variant-dropdown .vs__dropdown-toggle,
.product-variant-dropdown .vs__dropdown-menu {
  // border: none;
  color: var(--brand-primary, $brand-primary);
}

.product-variant-dropdown .vs__dropdown-toggle {
  border: 2px solid gainsboro;
}

.product-variant-dropdown .vs__dropdown-option--highlight {
  background: var(--brand-primary, $brand-primary);
}

.product-variant-dropdown .vs__clear,
.product-variant-dropdown .vs__open-indicator {
  fill: var(--brand-primary, $brand-primary);
}

.product-variant-dropdown .vs__dropdown-option {
  display: flex;
  gap: 2px;
  padding: 0 5px;
  align-items: center;
}

.product-variant-dropdown__img_wrapper {
  height: 40px;
  width: 40px;
  min-width: 35px;
  text-align: center;
  display: grid;
  align-items: center;
  overflow: hidden;
}
.vs__search {
  height: 45px !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.product-variant-dropdown {
  // border: 2px solid gainsboro;
  // border-radius: 6px;
  // padding: 5px;
  // font-size: 14px;
  min-width: 200px;

  img {
    margin: 0 auto;
    max-height: 35px;
    padding: 2px;
    max-width: 35px;
    display: block;
    max-width: 100%;
    height: auto;

    @media (max-width: 767px) {
      max-width: 36px;
      max-height: 36px;
    }
  }
}
</style>
